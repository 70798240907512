import React from "react"
import AttendProgram from "./attendProgram"
import { Link } from "gatsby"

export default function upcomingEvents() {
  return (
    <>
      <div className="container-custom">
        <div className="mt-5 pt-3 mb-4 px-5">
          {/* <Link
            to="/join-the-event"
            className="btn learn-more-btn my-4 "
            style={{ marginRight: "5px" }}
          >
            REGISTER FOR UPCOMING EVENTS
          </Link> */}
        </div>

        <div className="mb-5 px-5">
          <h1 className="heading mb-4"> UPCOMING EVENTS</h1>

          <div className="comman-para">
            One of the key objectives of The Indian ESG Network is to build a
            platform which provides opportunities with the common objective to
            build an Environment Conscious community, which contributes to the
            India's ESG conscious march from @75 years to @100 years of
            Independence.
          </div>
        </div>
        <AttendProgram />
      </div>
    </>
  )
}
